import React from 'react';
import { css } from "@emotion/react";
import SyncLoader from "react-spinners/SyncLoader";



const Loader = () => {

  const override = css`
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  `;

  return (
    <SyncLoader
    css={override}
    size={15}
    color={"#262626"}
    loading={true}
    />
    );
}


export default Loader;