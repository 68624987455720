import React from 'react';
import { Grid, Image, Icon } from 'semantic-ui-react';
import style from './Style.module.scss';
import { NavLink, } from 'react-router-dom';


const TopMenu = () => {

        return (
            <div className={ style.nav }>

            <Grid celled columns={3}>

            <Grid.Column>
            </Grid.Column>

            <Grid.Column className={ style.iconContent }>
            <NavLink to='/mainPage'>
            <Image src={ process.env.PUBLIC_URL + '/img/favicon.png' } />
            <p>Статус</p>
            </NavLink>
            </Grid.Column>

            <Grid.Column className={ style.phoneNumber }>
            <p><Icon name='call' size='tity'/>+7 903 364 92 58</p>
            </Grid.Column>

            </Grid>

            </div>
            )
}

export default TopMenu;