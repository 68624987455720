import React from 'react';
import { Button, Header, Modal, Card } from 'semantic-ui-react';
import style from './Style.module.scss';
import ImageOnLoad from './../ImageOnLoad/ImageOnLoad';

const ModalComponent = (props) => {

    const [open, setOpen] = React.useState(false);

    return (
        <Modal
        className={ style.modal }
        onClose={() => setOpen(false)}
        onOpen={() => setOpen(true)}
        open={open}
        trigger={
            <Card className={ style.card }>
            <ImageOnLoad src= { props.imageIconSrc }/>
            <Card.Content>
            <Card.Header>{ props.header }</Card.Header>
            </Card.Content>
            <Card.Content extra>
            <Card.Description >
            { props.price }
            </Card.Description>
            </Card.Content>
            </Card>
        }>


        <Modal.Header className={ style.header }>{ props.header }
        <Button onClick={() => setOpen(false)}>Х</Button>
        </Modal.Header>
        <Modal.Content image className={ style.imageInModal }>
        <ImageOnLoad src={ props.imageSrc } />
        <Modal.Description className={ style.mainContent }>
        <Header className={ style.header }>{ props.header }</Header>
        <p>{ props.description }</p>
        <p className={ style.price }>{ props.price }</p>
        </Modal.Description>
        </Modal.Content>
        <Modal.Actions>
        
        </Modal.Actions>
        </Modal>
        )
}


export default ModalComponent;