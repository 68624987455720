import React from 'react';
import style from './Style.module.scss';
import { Header } from 'semantic-ui-react';
import ImageOnLoad from './../../components/ImageOnLoad/ImageOnLoad';
import ImageGallery from 'react-image-gallery';
import "react-image-gallery/styles/scss/image-gallery.scss";

const AboutOurClinic = () => {

    const images = window.globalInfo.imagesAboutOurClinic;

    return (
        <div className={ style.mainBlock }>

        <Header as='h2' className={ style.header }>Современная стоматология статус</Header>

        <div className={ style.imageGallery }>
        <ImageGallery 
        showPlayButton='false' 
        items={images}
        onErrorImageURL={window.globalInfo.imgError}
        />
        </div>


        <div className={ style.textInform }>
        <div className={ style.textContent }>
        Мы не просто стоматология, а ещё и высокотехнологичный диагностический центр. Тщательное обследование позволяет врачам получить полную информацию о Вашем здоровье и составить оптимальный план лечения.
        </div>
        </div>

        <div className={ style.mainGoals }>
        <div className={ style.imageGoals}>
        <ImageOnLoad src={window.globalInfo.imageArmchair}/>
        </div>
        <div className={ style.blockGoals}>
        <Header className={ style.header }>Основные цели</Header>
        <div className={ style.textContent }>
        Крупная клиника, за плечами которой солидный опыт работы уже свыше 11 лет. И на протяжении всего этого времени мы не сдаём своих позиций, каждый день подтверждая статус лидирующей стоматологии в городе Бузулук. Наши врачи делают всё возможное, чтобы сохранить здоровье Ваших зубов. Просто расскажите нам о том, что Вас беспокоит, и мы обязательно поможем.
        </div>
        </div>
        </div>
        </div>
        )
}


export default AboutOurClinic;