import React, { useState } from 'react';
import { Menu, Image, Icon } from 'semantic-ui-react';
import style from './Style.module.scss';
import './Style-2.scss';
import { NavLink, } from 'react-router-dom';


const LeftMenu = () => {


    const [activeItem, handleItemClick] = useState('Главная');

    const closeMainMenu = () => {
        if (window.screen.width <= 720) {
            document.getElementById('btnMainMenuIsOpen').style.display = 'block';
            document.getElementById('btnMainMenuIsClose').style.display = 'none';
            document.getElementById('mainMenuBlockTop').style.display = 'none';
            document.querySelector('.' + style.phoneNumberBlock).style.top = '40px';
        }
        
    }

    const mainBlockIsActiveFunc = (e, state) => {
        document.getElementById('btnMainMenuIsOpen').style.display = 'block';
        document.getElementById('btnMainMenuIsClose').style.display = 'block';
        e.target.style.display = 'none';
        if (state === 'open') {
            document.getElementById('mainMenuBlockTop').style.display = 'flex';
            document.querySelector('.' + style.phoneNumberBlock).style.top = '230px';
        } else {
            document.getElementById('mainMenuBlockTop').style.display = 'none';
            document.querySelector('.' + style.phoneNumberBlock).style.top = '40px';
        }
    }


    return (
        <div className={ style.nav }>
        <div className={ style.blockDesc }>

        <Menu className={ style.topContent } pointing secondary vertical>

        <NavLink to='/mainPage' className={ style.topContentIconLink } >
        <Image src={ process.env.PUBLIC_URL + '/img/faviconWhite.png' } />
        <p>Статус</p>
        </NavLink>

        <div className={ style.phoneNumberBlock }>
        <p><Icon name='call' size='tity'/>+7 903 364 92 58</p>
        </div>

        <div className={ style.topContentMobile }>
        <NavLink to='/mainPage' className={ style.linkIcon }>
        <Image src={ process.env.PUBLIC_URL + '/img/faviconWhite.png' } />
        </NavLink>
        <p>Статус</p>
        <div className={ style.buttonMenu }>
        <Icon
        id='btnMainMenuIsOpen'
        onClick={ e => mainBlockIsActiveFunc(e, 'open') } 
        name='sidebar' x
        size='big' />
        <Icon
        id='btnMainMenuIsClose'
        onClick={ e => mainBlockIsActiveFunc(e, 'close') } 
        name='close' 
        size='big' />
        </div>
        </div>

        </Menu>




        <Menu id='mainMenuBlockTop' className={ style.mainContent } pointing secondary vertical>

        <NavLink to='/mainPage'>
        <Menu.Item
        name='Главная'
        content='Главная'
        active={activeItem === 'Главная'}
        onClick={(e) => { handleItemClick(e.name); closeMainMenu()}}
        />
        </NavLink>

        <NavLink to='/services'>
        <Menu.Item
        name='Услуги'
        content='Услуги'
        active={activeItem === 'Услуги'}
        onClick={(e) => { handleItemClick(e.name); closeMainMenu()}}
        />                 
        </NavLink>

        <NavLink to='/aboutOurClinic'>
        <Menu.Item
        name='О нашей клинике'
        content='О нашей клинике'
        active={activeItem === 'О нашей клинике'}
        onClick={(e) => { handleItemClick(e.name); closeMainMenu()}}
        />
        </NavLink>


        <NavLink to='/specialists'>
        <Menu.Item
        name='Специалисты'
        content='Специалисты'
        active={activeItem === 'Специалисты'}
        onClick={(e) => { handleItemClick(e.name); closeMainMenu()}}
        />
        </NavLink>

        <NavLink to='/contacts'>
        <Menu.Item
        name='Контакты'
        content='Контакты'
        active={activeItem === 'Контакты'}
        onClick={(e) => { handleItemClick(e.name); closeMainMenu()}}
        />
        </NavLink>
        </Menu>


        <Menu className={ style.bottomContent } pointing secondary vertical>

        <NavLink to='/aboutTheClinic'>
        <Menu.Item 
        name='О клинике'
        content='О клинике'
        active={activeItem === 'О клинике'}
        onClick={(e) => { handleItemClick(e.name); closeMainMenu()}}
        />
        </NavLink>

        <a href={window.globalInfo.pdfRules} rel="noreferrer" target='_blank'>
        <Menu.Item 
        name='Правила по предоставлению'
        content='Правила по предоставлению'
        active={activeItem === 'Правила по предоставлению'}
        onClick={(e) => { handleItemClick(e.name); closeMainMenu()}}
        />
        </a>

        <a href={window.globalInfo.paymentRegulations} rel="noreferrer" target='_blank'>
        <Menu.Item
        name='Положение по предоставлению'
        content='Положение по предоставлению'
        active={activeItem === 'Положение по предоставлению'}
        onClick={(e) => { handleItemClick(e.name); closeMainMenu()}}
        />
        </a>

        <NavLink to='/privacyPolicy'>
        <Menu.Item
        name='Политика конфиденциальности'
        content='Политика конфиденциальности'
        active={activeItem === 'Политика конфиденциальности'}
        onClick={(e) => { handleItemClick(e.name); closeMainMenu()}}
        />
        </NavLink>

        <Menu pointing secondary vertical>
        <Menu.Item
        size='big'
        icon='instagram outline'
        rel="noreferrer"
        href='https://www.instagram.com/status_dent/?utm_source=ig_profile_share&igshid=11304zc2ru4o0' target='_blank'
        />
        </Menu>

        </Menu>
        </div>

        </div>
        )
}


export default LeftMenu;