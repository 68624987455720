import React, { useState, useEffect } from 'react';
import style from './Style.module.scss';
import SpecialistsPageBlockContent from './../../components/SpecialistsPageBlockContent/SpecialistsPageBlockContent';
import ErrorSegment from './../../components/ErrorSegment/ErrorSegment';
import Loader from './../../components/Loader/Loader';


const Specialists = () => {

    const [popout, setPopout] = useState(<div className={ style.loaderSpecialists }><Loader /></div>);
    let specialists = window.globalInfo.jsonSpecialists;
    let isError = false;

    useEffect(() => {
        async function fetchRequest() {
            if (window.globalInfo.jsonSpecialists.length < 1) {
                let response = await fetch(window.globalInfo.jsonSpecialistsUrl);
                specialists = await response.json();
                window.globalInfo.jsonSpecialists = specialists;
                setPopout(null);
            } else {
                isError = true;
                setPopout(null);
            }
        }
        fetchRequest();

    });


    return (
        <div className={ style.blockSpecialist }>
        {
            popout !== null ? popout : ( isError === true ? <ErrorSegment /> :
                <div className={ style.mainBlockContent }>
                {
                    specialists.map(e => {
                        return (
                            <SpecialistsPageBlockContent 
                            imageSrc={`${e.imageSrc}`}
                            fullName={`${e.fullName}`}
                            post={`${e.post}`}
                            workExperience={`${e.workExperience}`}
                            />
                            )
                    })
                }
                </div>
                )
        }
        </div>
        )
}


export default Specialists;