import React from 'react';
import style from './Style.module.scss';
import ImageOnLoad from './../../components/ImageOnLoad/ImageOnLoad';
import ElementorDivider from './../../components/ElementorDivider/ElementorDivider';


const MainPage = () => (

<div className={ style.mainBlockContent }>

    <div className={ style.cardBlock }>
        <div className={ style.imageBlock }>
            <ImageOnLoad src={window.globalInfo.imageLibrary} />
        </div>
        <div className={ style.contentBlock } >
            <div className={ style.title }>Информация для пациентов</div>
            <ElementorDivider/>
            <div className={ style.infoText }>
                <p>Нашим пациентам мы гарантируем квалифицированную стоматологическую помощь и полную безопасность при лечении.</p>
                <p>Наличие мероприятий по стеририлизации "АнтиСПИД" и "АнтиГепатит" гарантируем полную эпидемиологическую безопасность.</p>
                <p>Мы делаем все для удобства своих пациентов, оказывая широкий спектр стоматологических услуг. С нами в тандеме работает зуботехническая лаборатория ООО "Артис-Дент", директор которой является Степанов И.Ю.</p>
                <p>-Политика конфиденциальности</p>
                <p>-Контролирующие органы</p>
                <p>-Правила первичной записи</p>
                <p>-Положение о порядке и условиях предоставляемых платных медицинских услуг пациентам в ООО "Статус".</p>
            </div>
        </div>
    </div>

    <div className={ style.cardBlock }>
        <div className={ style.imageBlock }>
            <ImageOnLoad src={window.globalInfo.imageTherapist} />
        </div>
        <div className={ style.contentBlock } >
            <div className={ style.title }>Терапевтическая стоматология</div>
            <ElementorDivider/>
            <div className={ style.infoText }>
                <p>Качественное лечение зубов с использование новейших технологий и материалов ведущих мировых компаний дает возможность сохранить здоровье ваших зубов надолго.</p>
            </div>
        </div>
    </div>

    <div className={ style.cardBlock }>
        <div className={ style.imageBlock }>
            <ImageOnLoad src={window.globalInfo.imagePodiatrist} />
        </div>
        <div className={ style.contentBlock } >
            <div className={ style.title }>Ортопедическая стоматология</div>
            <ElementorDivider/>
            <div className={ style.infoText }>
                <p>Протезирование съемными и несъемными конструкциями при частичном и полном отсутствии зубов,микропротезирование.</p>
            </div>
        </div>
    </div>

</div>
)


export default MainPage;