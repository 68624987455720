import React from 'react';
import style from './Style.module.scss';


const AboutTheClinic = () => (

<div className={ style.mainBlockContent }>
    <div className={ style.header }>
        Информация для пациентов
    </div>

    <div className={ style.textBlock}>
        <p>Медико - санитарная помощь включает в себя осуществление специализированной медицинской помощи по: организации здравоохранения и общественному здоровью; стоматологии общей практики; стоматологии терапевтической; стоматологии ортопедической.</p>
        <p>Медико - санитарная помощь включает в себя осуществление специализированной медицинской помощи по: организации здравоохранения и общественному здоровью; стоматологии общей практики; стоматологии терапевтической; стоматологии ортопедической.</p>
        <p>Лечащим врачом, то есть врачом, оказывающим стоматологическую помощь пациенту в период его наблюдения и лечения в Стоматологии «Статус» является:</p>
        <p><span className={ style.fullName }>Малюкова Дарья Павловна </span> – главный врач, врач стоматолог общей практики.</p>
        <p><span className={ style.fullName }>Поминова Ольга Олеговна </span> – врач-стоматолог общей практики, врач-стоматолог-терапевт, врач-стоматолог-ортопед.</p>
        <p><span className={ style.fullName }>Бердина Лариса Николаевна </span> – зубной врач.</p>
        <p>Лечащий врач назначается по выбору пациента или специалистами регистратуры по согласованию с пациентом. Лечащий врач организует своевременное и квалифицированное обследование полости рта и лечение пациента, предоставляет информацию о состоянии его стоматологического здоровья, в необходимых случаях направляет на консультации к врачам-специалистам. Рекомендации врачей-консультантов реализуются по согласованию с лечащим врачом, за исключением случаев, угрожающих жизни пациента. Лечащий врач по согласованию с главным врачом может отказаться от наблюдения и лечения пациента, если это не угрожает жизни самого пациента, в случаях несоблюдения пациентом врачебных предписаний, условий заключенного с ним Договора (в случае его заключения)</p>
        <p>Приём врачей всех специальностей осуществляется по предварительной записи.</p>
        <p>Телефон для записи на прием: <span className={ style.fullName }>4-02-42, +7-903-364-92-58</span></p>
    </div>

    <div className={ style.header }>Общество с ограниченной ответственностью «Статус»</div>

    <div className={ style.textBlock}>
        <p>461040, Оренбургская обл., г. Бузулук, ул. Ново-Чапаевская, д. 183,
        ИНН 5603035644, КПП 560301001 Свидетельство выдано Межрайонной инспекцией Федеральной налоговой службой № 10 по Оренбургской области 25 июня 2010года. Серия 56 № 3149549
        </p>
        <p>ОГРН 1105658013867 Свидетельство выдано Межрайонной инспекцией Федеральной налоговой службой № 10 по Оренбургской области 25 июня 2010года. Серия 56 № 3149550</p>
        <p>р/с № 40702810246200001527 , в Отделение № 8623 Сбербанка России г. Оренбург, к/с301 018 106 000 000 006 01, БИК 045 354 60</p>
        <p>Лицензия на осуществление медицинской деятельности № ЛО – 56 – 01 - 001756 выданной 14.06.2016г (При оказании первичной, в том числе доврачебной, врачебной и специализированной, медико-санитарной помощи организуются и выполняются следующие работы (услуги): при оказании первичной доврачебной медико-санитарной помощи в амбулаторных условиях по: - сестринскому делу, - стоматологии; при оказании первичной специализированной медико-санитарной помощи в амбулаторных условиях по: - организации здравоохранения и общественному здоровью; - стоматологии общей практики; - стоматологии терапевтической; - стоматологии ортопедической, выдана Министерством здравоохранения Оренбургской области. Адрес: 460006, г. Оренбург, ул. Терешковой, д. 33, тел: (3532) 77-45-19</p>
        <p>Генеральный директор – <span className={ style.fullName }>Малюков Павел Анатольевич</span>, действующий на основании Устава</p>
        <p><span className={ style.fullName }>Тел: 4–02–42, +7-903-364-92-58</span></p>
    </div>

    <div className={ style.header }>
        ПОРЯДОК ОБРАЩЕНИЯ ПАЦИЕНТОВ В СТОМАТОЛОГИЮ «СТАТУС»
    </div>

    <div className={ style.textBlock}>
        <p>Прием пациентов возможен как по предварительной записи, так и в день обращения в ООО «Статус». Медико-санитарную помощь в амбулаторных условиях по экстренным показаниям (в рамках Лицензии) предоставляется в момент обращения, независимо от места проживания и наличия документов, вне очереди и без предварительной записи. Во всех случаях записи на приём, при первом посещении врача пациент приходит в назначенный день не позже, чем за 15 минут до начала приема. Если в назначенный день прием гражданина невозможен (по причине внепланового отпуска, болезни врача, или по иному основанию, не зависящему от гражданина), гражданин уведомляется об этом в кратчайшие сроки любым доступным способом (непосредственно при личном обращении гражданина в Учреждение, при предоставлении гражданином информации: по телефону). Если в назначенный день прием к врачу невозможен по причине, зависящей от пациента, он уведомляет об этом в кратчайшие сроки любым доступным способом регистратуру ООО «Статус» (личная явка либо по телефонам:</p>
        <p><span className={ style.fullName }>Тел: 4–02–42, +7-903-364-92-58</span></p>
        <p>Лечащий врач имеет право отказать в приеме (или переназначить) ПАЦИЕНТА (кроме случаев требующих экстренного вмешательства) в случае:</p>
        <p>- состояния алкогольного, наркотического или токсического опьянения;</p>
        <p>- если действия Пациента угрожают жизни и здоровью персонала;</p>
        <p>- требования услуг, которые не входят в план лечения, зафиксированный в медицинской карте;</p>
        <p>- опоздания на прием более 15 минут;</p>
        <p>- при серьезном нарушении графика приема, если это нарушение вызвано объективными причинами, то есть не подлежавшими прогнозу осложнениями, возникшими при лечении других ПАЦИЕНТОВ;</p>
        <p>По окончании первого приёма лечащий врач выдаёт пациенту листок назначений, в котором прописывается дата и время повторного и последующего приёмов. В случае, если пациент не может прийти на приём к своему лечащему врачу в назначенное время, пациент обязан сообщить об этом по телефонам <span className={ style.fullName }>Тел: 4–02–42, +7-903-364-92-58</span> или любыми другими доступными способами не менее чем за 2 часа до назначенного времени.</p>
    </div>

    <div className={ style.header }>Контролирующие организации и их адрес:</div>

    <div className={ style.textBlock}>
        <p><span className={ style.fullName }>Администрация Оренбургской области</span></p>
        <p>Губернатор Паслер Денис Владимирович</p>
        <p>Адрес администрации 460015, Оренбургская область, г. Оренбург, Дом Советов</p>
        <p>Телефоны администрации +7 (3532) 77-69-31, (3532) 78-60-10, (3532) 77-96-00, факс: (3532) 77-38-02</p>
        <p>Официальный сайт www.orenburg-gov.ru</p>
    </div>

    <div className={ style.textBlock}>
        <p><span className={ style.fullName }>Министерство здравоохранения Оренбургской области.</span></p>
        <p>Адрес: 460006, г. Оренбург, улица Терешковой, дом 33</p>
        <p>Телефон: (3532) 77-35-44</p>
        <p>Министр здравоохранения Оренбургской области – Савинова Татьяна Леонидовна</p>
    </div>

    <div className={ style.textBlock}>
        <p><span className={ style.fullName }>Администрация города Бузулука</span></p>
        <p>Глава города: Песков Владимир Сергеевич</p>
        <p>Адрес: 461040, Оренбургская область, г Бузулук, ул. Ленина д. 10</p>
        <p>Телефон приемной главы города: 8(35342) 2-24-25</p>
    </div>

    <div className={ style.textBlock}>
        <p><span className={ style.fullName }>ГБУЗ «Городская Больница» города Бузулука</span></p>
        <p>Адрес: 461040, г. Оренбург, улица 1 Мая, дом 1</p>
        <p>Телефон: 2-50-01</p>
        <p>Главный врач МУЗ "ЦГБ города Бузулука": Кадочкин Сергей Юрьевич</p>
    </div>

    <div className={ style.textBlock}>
        <p><span className={ style.fullName }>Западный территориальный отдел Управления Роспотребнадзора по Оренбургской области в городе Бузулуке</span></p>
        <p>Адрес: 461046 Оренбургская область, город Бузулук, 4 микрорайон, дом 1 Б</p>
        <p>Телефон: 5-77-51</p>
        <p>Начальник Западного территориального отдела Управления Роспотребнадзора по Оренбургской области Главный государственный санитарный врач по г. Бузулуку, Бузулукскому, Грачевскому, Курманаевскому, Тоцкому, Первомайскому районам Оренбургской области: Гончаров Сергей Николаевич</p>
    </div>

    <div className={ style.header }>
        <p>При возникновении конфликта между пациентом и врачом, средним или младшим медицинским персоналом звоните по телефону:</p>
        <p>4–02–42</p>
    </div>
</div>
)


export default AboutTheClinic;