import React from 'react';
import style from './Style.module.scss';
import ImageOnLoad from './../ImageOnLoad/ImageOnLoad';
import ElementorDivider from './../ElementorDivider/ElementorDivider';


const SpecialistsPageBlockContent = ({imageSrc, fullName, post, workExperience}) => (

<div className={ style.cardBlock }>
    <div className={ style.imageBlock }>
    	<ImageOnLoad src={imageSrc} />
    </div>

    <div className={ style.contentBlock } >
        <div className={ style.title }>{fullName}</div>
        <ElementorDivider/>
        <div className={ style.infoText }>{post}</div>
        <div className={ style.infoText }>{workExperience}</div>
    </div>
</div>
)


export default SpecialistsPageBlockContent;