import React from 'react';
import style from './Style.module.scss';

const Footer = () => {


    return (
        <div className={ style.footer }>
        © {window.globalInfo.year} Стоматология статус.
        </div>
        )

}


export default Footer;