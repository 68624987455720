import React from 'react';
import style from './Style.module.scss';
import YMapsStatus from './../../components/YMapsStatus/YMapsStatus';
import { Icon } from 'semantic-ui-react';


const Contacts = () => (

<div className={ style.blockContacts }>

    <div className={ style.header }>Контакты</div>

    <div className={ style.blockAddress }>
        <div>
            <p>Время работы:</p>
            <p>Понедельник - 9:00-20:00</p>
            <p>Вторник - 9:00-20:00</p>
            <p>Среда - 9:00-20:00</p>
            <p>Четверг - 9:00-20:00</p>
            <p>Пятница - 9:00-20:00</p>
            <p>Суббота - Выходной</p>
            <p>Воскресенье - Выходной</p>
        </div>

        <div>
            <p>Адрес:</p>
            <p>Оренбургская обл., г. Бузулук</p>
            <p>ул. Ново-Чапаевская</p>
            <p>дом - 183</p>
        </div>

        <div>
            <p>Контактный телефон:</p>
            <p>+7 903 364 92 58</p>
        </div>

        <div>
            <p>Мы в соцсетях:</p>
            <a href='https://www.instagram.com/status_dent/?utm_source=ig_profile_share&igshid=11304zc2ru4o0'>
                <Icon name='instagram' size='big'/>
            </a>
        </div>
    </div>

    <div className={ style.blockMap }>
        <YMapsStatus />
    </div>

</div>
)


export default Contacts;