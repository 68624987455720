import React from 'react';
import style from './Style.module.scss';


const ElementorDivider = () => {

    return (
        <div className={style.elementorDivider}>
        <span className={style.elementorDividerSeparator}></span>
        </div>
        )
    
}

export default ElementorDivider;