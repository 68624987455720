import React from 'react';
import style from './Style.module.scss';


const PrivacyPolicyPage = () => {


    return (
        <div className={ style.mainBlockContent }>

        <div className={ style.header }>ПОЛИТИКА КОНФИДЕНЦИАЛЬНОСТИ СТОМАТОЛОГИЧЕСКОЙ КЛИНИКИ «Статус»</div>
        <div className={ style.textBlock}>
        Стоматологическая клиника - ООО «Статус», руководствуясь законом о защите персональных данных, хранит личную информацию клиентов в тайне. Для этой цели мы используем современные способы и технологии. Нашими специалистами разработана политика конфиденциальности, применимая к персональным материалам, которые используются базой данных стоматологии и на сайте.
        </div>


        <div className={ style.header }>1. ПОЛУЧЕНИЕ ИНФОРМАЦИИ</div>
        <div className={ style.textBlock}>
        <p>После того как вы предоставите нам личную информацию, мы её обрабатываем и храним, обеспечив защиту на высоком уровне.Ваши персональные сведения не будут доступны для третьих лиц. Исключение составляют случаи, предусмотренные законодательством РФ.Сотрудники стоматологии имеют право требовать предоставить следующие контактные данные:
        </p>

        <p>- фамилию, имя, отчество;</p>
        <p>- адрес электронной почты;</p>
        <p>- номера телефонов;</p>
        <p>- сведения об аккаунтах в социальных сетях;</p>
        <p>- иную информацию, необходимую для получения всех видов услуг.</p>
        <p>В случае отказа предоставить соответствующие материалы мы вправе расторгнуть договор об обслуживании в нашей клинике. Уведомление о расторжении договора для экономии времени будет отправлено в автоматическом режиме после получения вашего отказа. Мы вправе пополнять ваши исходные данные сведениями, полученными из других источников с целью предоставления более полного пакета услуг. Способ проверки достоверности полученного материала мы выбираем, опираясь на наши технические возможности. Все сведения об услугах, размещаемые на сайте стоматологической клиники «Статус» соответствует регламенту и подкреплены законодательной базой. Предоставляя нам ваши персональные сведения, вы соглашаетесь с действующей политикой конфиденциальности.</p>
        </div>


        <div className={ style.header }>2. ИСПОЛЬЗОВАНИЕ ВАШИХ ЛИЧНЫХ ДАННЫХ</div>
        <div className={ style.textBlock}>
        <p>Материалы, полученные от вас, будут использованы специалистами стоматологической клиники «Статус» исключительно с целью предоставления всех возможных услуг. Нам необходимы ваши персональные данные для связи с вами наиболее удобными способами. Пользователи вправе выбрать способ получения необходимых им сведений о мероприятиях, проводимых стоматологией, сделав запрос по электронной почте. Вся информация размещена на нашем сайте. Регистрируясь на Сайте, и используя Сайт и Сервисы, Пользователь выражает свое полное согласие с условиями настоящей Политики.
        </p></div>



        <div className={ style.header }>3. ПЕРЕДАЧА ЛИЧНЫХ ДАННЫХ КЛИЕНТА НАШИМ ПАРТНЕРАМ</div>
        <div className={ style.textBlock}>
        <p>Руководство стоматологической клиники «Статус» не предоставляет сведения о своих пациентах сторонним организациям и частным лицам. Мы допускаем передачу информации о вас, предварительно получив ваше согласие на это, своим партнёрам и подрядчикам в случаях, когда требуются дополнительные услуги, предоставляемые ими. Наши специалисты и партнёры обязаны хранить личные материалы клиентов в строжайшей тайне и не имеют права их использовать без полученного на то согласия.Всю информацию пациентов партнёры и подрядчики нашей стоматологии обязуются применять только в целях оказания необходимых услуг.</p>
        </div>



        <div className={ style.header }>4. МЕРЫ БЕЗОПАСНОСТИ</div>
        <div className={ style.textBlock}>
        <p>Стоматологическая клиника «Статус» обеспечивает безопасность личной информации своих пациентов с помощью всех необходимых организационно-правовых и технических мер. Мы защищаем личные сведения от несанкционированных или случайных доступов, изменений, блокировки, распространения, размножения, иных неправомерных действий. Для этой цели мы используем современные, технологичные программы и устройства. Все действия, применяемые для безопасности персональных данных пользователей, соответствуют необходимым регламентам и организационно-правовым нормам.</p>
        </div>


        <div className={ style.header }>5. СОГЛАСИЕ НА ОБРАБОТКУ ПЕРСОНАЛЬНЫХ ДАННЫХ</div>
        <div className={ style.textBlock}>
        <p>Получение информации, хранение, обработка, передача, использование её происходят только с согласия пациентов. Предоставляя нам все нужные материалы, вы тем самым соглашаетесь на любую их обработку в рамках этого соглашения.</p>
        </div>


        <div className={ style.header }>6. ИЗМЕНЕНИЯ ПОЛИТИКИ КОНФИДЕНЦИАЛЬНОСТИ</div>
        <div className={ style.textBlock}>
        <p>Сотрудники стоматологической клиники «Статус» имеют право редактировать, пополнять и обновлять действующую политику конфиденциальности. Материалы об этих изменениях мы заносим в соответствующие документы с указанием даты редакции и публикуем на нашем сайте. Новая политика конфиденциальности в соответствующей редакции вступает в силу с момента публикации на нашем сайте. Дальнейшее пользование услугами и сервисами нашей стоматологии будет проходить в соответствии со всеми изменениями.</p>
        </div>


        <div className={ style.header }>7. ЗАЩИТА ИНФОРМАЦИИ НА САЙТЕ</div>
        <div className={ style.textBlock}>
        <p>Все содержимое сайта является собственностью ООО «Статус» и защищено законодательно. Пользователи вправе использовать информацию, представленную на сайте, в личных и некоммерческих целях. Запрещено любое использование информации в коммерческих целях. Компания оставляет за собой право на изменение, удаление или другую работу с информацией, представленной на сайте, а также на ограничение доступа к сайту. Компания не несет ответственности за сведения, представленные Потребителями на сайте в общедоступной форме. Компания оставляет за собой право на изменение Политики конфиденциальности для дальнейшего совершенствования системы безопасности в соответствии с действующим законодательством.</p>
        </div>


        <div className={ style.header }>8. ОТКАЗ ОТ ОТВЕТСТВЕННОСТИ</div>
        <div className={ style.textBlock}>
        <p>Помните, передача информации личного характера при посещении сторонних сайтов, включая сайты компаний-партнеров, даже если веб-сайт содержит ссылку на Сайт или на Сайте есть ссылка на эти веб-сайты, не подпадает под действия данного документа. Администрация Сайта не несет ответственности за действия других веб-сайтов. Процесс сбора и передачи информации личного характера при посещении этих сайтов регламентируется документом «Защита информации личного характера» или аналогичным, расположенном на сайтах этих компаний.</p>
        </div>


        <div className={ style.header }>9. ОБРАТНАЯ СВЯЗЬ</div>
        <div className={ style.textBlock}>
        <p>Мы ответим на все интересующие вас вопросы, учтём все пожелания и замечания, предоставим необходимую информацию о политике конфиденциальности. Постоянно поддерживать связь с нами можно по электронной почте:</p>
        </div>

        </div>

        )
}


export default PrivacyPolicyPage;