import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import { HashRouter } from 'react-router-dom';

const kIsRelease = true;

const mainUrl   = kIsRelease ? 'https://стоматология-статус.рф/' : 'http://127.0.0.1:5500/';
const jsonUrl   = kIsRelease ? mainUrl + 'backend/JSON/'         : mainUrl + 'JSON/';
const imagesUrl = kIsRelease ? mainUrl + 'backend/img/'          : mainUrl + 'img/';
const docsUrl   = kIsRelease ? mainUrl + 'backend/docs/'         : mainUrl + 'docs/';


window.globalInfo = {
    year: new Date().getFullYear(),

    arrTitle: {
        '/'               : 'Главная страница',
        '/mainPage'       : 'Главная страница',
        '/services'       : 'Услуги',
        '/aboutOurClinic' : 'О нашей клинике',
        '/contacts'       : 'Контакты',
        '/specialists'    : 'Специалисты',

        '/aboutTheClinic' : 'О клинике',
        '/rules'          : 'Правила по предоставлению',
        '/provision'      : 'Положение по предоставлению',
        '/privacyPolicy'  : 'Политика конфиденциальности'
    },
    errTitle: 'Страница не найдена. Ошибка 404.',

    jsonServicesUrl     : jsonUrl + 'servicesJSON.json',
    jsonSpecialistsUrl  : jsonUrl + 'specialistsJSON.json',


    imgError        : imagesUrl + 'image/Build_tooth.png',
    imageArmchair   : imagesUrl + 'image/task.jpg',
    imageLibrary    : imagesUrl + 'image/library.jpg',
    imageTherapist  : imagesUrl + 'image/therapist.jpg',
    imagePodiatrist : imagesUrl + 'image/podiatrist.jpg',

    pdfRules           : docsUrl + 'rulesOfMedicalServices.pdf',
    paymentRegulations : docsUrl + 'paymentRegulations.doc',

    jsonServices: [],
    jsonSpecialists: [],

    imagesAboutOurClinic: [
    {
        original: imagesUrl + 'ImgClinic/1.jpg',
        thumbnail: imagesUrl + 'ImgClinic/1.jpg',
    },
    {
        original: imagesUrl + 'ImgClinic/2.jpg',
        thumbnail: imagesUrl + 'ImgClinic/2.jpg',
    },
    {
        original: imagesUrl + 'ImgClinic/3.jpg',
        thumbnail: imagesUrl + 'ImgClinic/3.jpg',
    },
    {
        original: imagesUrl + 'ImgClinic/4.jpg',
        thumbnail: imagesUrl + 'ImgClinic/4.jpg',
    },
    {
        original: imagesUrl + 'ImgClinic/5.jpg',
        thumbnail: imagesUrl + 'ImgClinic/5.jpg',
    },
    {
        original: imagesUrl + 'ImgClinic/6.jpg',
        thumbnail: imagesUrl + 'ImgClinic/6.jpg',
    },
    ]
};


ReactDOM.render(
    <HashRouter>
    <App />
    </HashRouter>
    ,document.getElementById('root')
    );


// window.globalInfo.images