import React from 'react';
import { Divider, Header, Segment } from 'semantic-ui-react';
import ImageOnLoad from './../ImageOnLoad/ImageOnLoad';
import style from './Style.module.scss';

const ErrorSegment = () => {

  return (
    <Segment className={ style.segment }>
    <Header as='h2' >Ошибка подключения</Header>
    <Divider clearing />
    <ImageOnLoad src={window.globalInfo.sadTooth} />
    </Segment>
    );
}


export default ErrorSegment;