import React from 'react';
import { YMaps, Map, Placemark } from 'react-yandex-maps';


const YMapsStatus = () => {

    return (
        <YMaps>
        <Map
        defaultState={{
            center: [52.781626, 52.252459],
            zoom: 16
        }}
        width='100%'
        height='100%'
        >
        <Placemark geometry={[52.781626, 52.252459]} />
        </Map>
        </YMaps>
        )
}

export default YMapsStatus;