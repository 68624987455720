import React, { useState, useEffect } from 'react';
import style from './Style.module.scss';
import ModalComponent from './../../components/ModalComponent/ModalComponent';
import ErrorSegment from './../../components/ErrorSegment/ErrorSegment';
import Loader from './../../components/Loader/Loader';

const MainPage = () => {

    const [popout, setPopout] = useState(<div className={ style.loaderServices }><Loader /></div>);
    let services = window.globalInfo.jsonServices;
    let isError = false;

    useEffect(() => {
        async function fetchRequest() {
            if (services.length < 1) {
                let response = await fetch(window.globalInfo.jsonServicesUrl);
                services = await response.json();
                window.globalInfo.jsonServices = services;
                setPopout(null);
            } else {
                isError = true;
                setPopout(null);
            }
        }
        fetchRequest();

    });


    return (
        <div className={ style.blockServices }>
        {
            popout !== null ? popout : ( isError === true ? <ErrorSegment /> :
                services.map(ser => {    
                    return (
                        <div className={ style.mainBlockContent }>
                        <div className={ style.namePriceList }>{ser.namePriceList}</div>
                        {
                            ser.arr.map(e => {
                                return (
                                    <ModalComponent
                                    imageSrc={`${e.imageSrc}`}
                                    imageIconSrc={`${e.imageIconSrc}`}
                                    header={`${e.header}`}
                                    price={`${e.price}`}
                                    description={`${e.description}`}
                                    />
                                    )
                            })
                        }
                        </div>
                        )
                })
                )
        }
        </div>
        )
}


export default MainPage;