import React from 'react';
import Loader from './../Loader/Loader';
import ImageLoader from 'react-loading-image';

const ImageOnLoad = ({src}) => {

    return (
        <ImageLoader
        src={src}
        image={() => <img src={src} alt='Фото' />}
        loading={() => <Loader/>}
        error={() => <img src={window.globalInfo.imgError} alt='Фото' />}
        />
        );
}

export default ImageOnLoad;
