import React from "react";
import { Route, useLocation } from 'react-router-dom';
import 'semantic-ui-css/semantic.min.css';
import LeftMenu from './components/LeftMenu/LeftMenu';
import TopMenu from './components/TopMenu/TopMenu';
import Footer from './components/Footer/Footer';
import MainPage from './panels/MainPage/MainPage';
import ServicesPage from './panels/ServicesPage/ServicesPage';
import AboutOurClinic from './panels/AboutOurClinic/AboutOurClinic'
import Contacts from './panels/Contacts/Contacts';
import Specialists from './panels/Specialists/Specialists';
import AboutTheClinic from './panels/AboutTheClinic/AboutTheClinic';
import PrivacyPolicyPage from './panels/PrivacyPolicyPage/PrivacyPolicyPage';
import './main.scss';


const App = () => {

    const arrTitle = window.globalInfo.arrTitle;

    function usePageViews() {
        let location = useLocation();
        React.useEffect(() => {
            window.document.title = arrTitle[location.pathname] ? arrTitle[location.pathname] : window.globalInfo.errTitle;
            window.scrollTo(0,0);
        }, [location]);
    }
    usePageViews();



    return (
        <div className='main'>
        
        <div className='leftMenu'>
        <LeftMenu />
        <TopMenu />
        </div>

        <div className='mainContent'>
        
        <Route exact path='/mainPage' component={MainPage} />
        <Route exact path='/services' component={ServicesPage} />
        <Route exact path='/aboutOurClinic' component={AboutOurClinic} />
        <Route exact path='/specialists' component={Specialists} />
        <Route exact path='/contacts' component={Contacts} />

        <Route exact path='/aboutTheClinic' component={AboutTheClinic} />
        <Route exact path='/privacyPolicy' component={PrivacyPolicyPage} />
        <Route exact path='/' component={MainPage} />


        <Footer />
        </div>
        
        </div>
        );
}

export default App;
